import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, CircularProgress } from "@mui/material";
import { Stack } from "@mui/system";
import { FieldErrors, SelectElement, useForm } from "react-hook-form-mui";
import { z } from "zod";
import { useAppDispatch, useAppSelector } from "../store";
import { setAccount, stepForward } from "../store/aifCreationForm";
import { isPresent } from "../utils";
import { useAwsAccounts } from "../store/awsAccounts";
import { Link } from "react-router-dom";

const FormSchema = z.object({
	account: z.string().min(1),
});
type FormSchema = z.infer<typeof FormSchema>;
const SelectOption = z.object({
	id: z.string().min(1),
	label: z.string().min(1),
	disabled: z.boolean().optional(),
});
type SelectOption = z.infer<typeof SelectOption>;

export default function AwsAccountSelectForm() {
	const dispatch = useAppDispatch();
	const storedAccount = useAppSelector(
		(store) => store.ocnCreationForm.accountSetup,
	);

	const { handleSubmit, control, formState, watch, setValue } = useForm({
		resolver: zodResolver(FormSchema),
		defaultValues: {
			account: storedAccount?.id ?? "",
		},
	});
	const values = watch();

	const { list, status, error } = useAwsAccounts();

	const accountOptions: SelectOption[] = [
		{
			id: "1",
			label: "Select an account...",
			disabled: true,
		},
	];

	list.forEach((account) =>
		accountOptions.push({
			id: account.id,
			label: `${account.name} (${account.external_id})`,
		}),
	);

	// Automatically handle account selection based on the number of accounts
	useEffect(() => {
		if (list.length === 0) {
			// No accounts - clear the current selection
			setValue("account", "");
		} else if (list.length === 1 || (list.length > 1 && !values.account)) {
			// Only one account - automatically select it
			// Multiple accounts - default to the first one if none is selected
			setValue("account", list[0].id);
		}
	}, [list, setValue, values.account]);

	const currentAccount = list.find((account) => values.account === account.id);

	const onSuccess = () => {
		if (isPresent(currentAccount)) {
			dispatch(stepForward());
			dispatch(setAccount(currentAccount));
		}
	};

	const onError = (err: FieldErrors<FormSchema>) => {
		console.error(err);
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSuccess, onError)}>
				<Stack spacing={2}>
					{error}
					{status === "loading" ? (
						<CircularProgress />
					) : (
						<Stack direction={"row"} spacing={2}>
							{accountOptions.length > 0 && (
								<SelectElement
									autoFocus
									required
									fullWidth
									variant="standard"
									control={control}
									label="Account ID"
									name="account"
									options={accountOptions}
								/>
							)}
							<Stack flexDirection={"column-reverse"}>
								<Link to="./add-aws-account">
									<Button variant="contained" type="button">
										Connect&nbsp;Account
									</Button>
								</Link>
							</Stack>
						</Stack>
					)}
					<Stack direction={"row"}>
						<Button
							disabled={!formState.isValid}
							variant="contained"
							size="small"
							type="submit"
						>
							Next
						</Button>
					</Stack>
				</Stack>
			</form>
		</>
	);
}
